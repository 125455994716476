import React, { Component } from "react";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";
import Header from "../layout/Header";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
import { FetchPost } from "../../helper/helpers";
import { withAuthUser, useAuthUser } from "react-auth-kit";

class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attendanceList: [],
      member_id: "",
      isCheckedIn: false,
      attendance_id: "",
      checkin_time: "",
      checkout_time: "",
    };
  }
  // const auth = useAuthUser();
  componentDidMount() {
    // console.log("auth data", this.props.authState);
    this.setState({ member_id: this.props.authState.id }, () => {
      this.getMember();
    });
  }
  getMember = () => {
    let postData = { fk_user_id: this.state.member_id };
    FetchPost("get-user-by-id", postData).then((result) => {
      console.log("member data : ", JSON.stringify(result.data));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.setState({
          firstname: result.data.firstname,
          lastname: result.data.lastname,
          email: result.data.email,
          phonenumber: result.data.phonenumber,
          aadharnumber: result.data.aadharnumber,
          joindate: result.data.joindate,
          dateofbirth: result.data.dateofbirth,
          city: result.data.city,
          height: result.data.height,
          weight: result.data.weight,
          state: result.data.state,
          country: result.data.country,
          password: result.data.password,
          address: result.data.address,
          zipcode: result.data.zipcode,
          checkincode: result.data.checkincode.toString(),
          relation: result.data.relation,
        });
        // console.log("post data is..", postData);
        // return false;
      }
    });
  };
  checkintime = () => {
    let postData = { member_id: this.state.member_id };
    FetchPost("checkin-time", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.getattendanceList();
      }
    });
  };
  checkouttime = () => {
    let postData = { attendance_id: this.state.attendance_id };
    FetchPost("checkout-time", postData).then((result) => {
      console.log("response data : ", JSON.stringify(result));
      this.setState({ isVisibleLoader: false });
      if (result.status === 1) {
        this.getattendanceList();
      }
    });
  };
  // validation = () => {
  //   if (this.state.firstname == "") {
  //     alert("First name is required");
  //     return false;
  //   }
  //   if (this.state.lastname == "") {
  //     alert("Last name is required");
  //     return false;
  //   }
  //   if (this.state.email == "") {
  //     alert("Email is required");
  //     return false;
  //   }
  //   if (this.state.password == "") {
  //     alert("Password is required");
  //     return false;
  //   }
  //   if (this.state.confirmpassword == "") {
  //     alert("Confirm Password is required");
  //     return false;
  //   }
  //   if (this.state.phoneno == "") {
  //     alert("Phone No is required");
  //     return false;
  //   }

  //   return true;
  // };

  updateState = (stateVar, stateVal) => {
    //console.log("State Var Vaue : ", stateVal);
    this.setState({
      [stateVar]: stateVal,
    });
  };
  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };
  submitAddMember = () => {
    if (this.validation()) {
      alert("form submited");
    }
  };
  render() {
    const {
      firstname,
      lastname,
      dateofbirth,
      address,
      phonenumber,
      email,
      city,
      state,
      country,
      zipcode,
      isCheckedIn,
      checkin_time,
      checkout_time,
    } = this.state;
    // const auth = useA
    console.log("auth data  are ", this.props.authState);

    return (
      <div className="wrapper">
        <div className="navbar-minimize-fixed">
          <button className="minimize-sidebar btn btn-link btn-just-icon">
            <i className="tim-icons icon-align-center visible-on-sidebar-regular text-muted"></i>
            <i className="tim-icons icon-bullet-list-67 visible-on-sidebar-mini text-muted"></i>
          </button>
        </div>
        <Sidebar></Sidebar>
        <div className="main-panel">
          <Header></Header>
          <div
            className="modal modal-search fade"
            id="searchModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="searchModal"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <input
                    type="text"
                    className="form-control"
                    id="inlineFormInputGroup"
                    placeholder="SEARCH"
                  />
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="tim-icons icon-simple-remove"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="row">
              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area1">
                <div className="container"></div>
                {/* <div>
                  <div className="box text-center">
                    <button type="button" className="btn1 btn-success">
                      Active
                    </button>
                  </div>
                </div> */}
                <div className="btn2">
                  <h2 className="text-center">
                    {firstname} {lastname}
                  </h2>
                  <Link to="/editprofile">
                    <button type="button" className="btn btn-success">
                      <i className="tim-icons icon-pencil"></i>Edit
                    </button>
                  </Link>
                  <p className="text5">PERSONAL DETAILS</p>
                  <div className="row">
                    <div className="col-sm-4">
                      <h5 className="text3">Date Of Birth</h5>
                    </div>
                    <div>
                      <h5 className="text4">{dateofbirth}</h5>
                    </div>
                  </div>
                  <hr
                    style={{
                      background: "gray",
                      color: "gray",
                      borderColor: "gray",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <div className="row">
                    <div className="col-sm-4">
                      <h5 className="text3">Phone Number</h5>
                    </div>
                    <div>
                      <h5 className="text4">{phonenumber}</h5>
                    </div>
                  </div>
                  <hr
                    style={{
                      background: "gray",
                      color: "gray",
                      borderColor: "gray",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <div className="row">
                    <div className="col-sm-4">
                      <h5 className="text3">Email</h5>
                    </div>
                    <div>
                      <h5 className="text4">{email}</h5>
                    </div>
                  </div>
                  <hr
                    style={{
                      background: "gray",
                      color: "gray",
                      borderColor: "gray",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  <div className="row">
                    <div className="col-sm-4">
                      <h5 className="text3">Address</h5>
                    </div>
                    <div>
                      <h5 className="text4">
                        {address}
                        <br />
                        {city},{state},{zipcode},{country}
                      </h5>
                    </div>
                  </div>
                  <hr
                    style={{
                      background: "gray",
                      color: "gray",
                      borderColor: "gray",
                      height: "2px",
                      width: "100%",
                    }}
                  />
                  {/* <p className="text5">FAMILY ACCOUNT</p>
                  <Link to="/addfamilymember">
                    <button type="button" className="btn btn-success">
                      Add Family Member
                    </button>
                  </Link> */}
                  <div className="box text-center">
                    {isCheckedIn === true && checkout_time === null && (
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          this.checkouttime();
                        }}
                      >
                        Check-Out
                      </button>
                    )}
                    {isCheckedIn === false && (
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={() => {
                          this.checkintime();
                        }}
                      >
                        Check-In
                      </button>
                    )}
                  </div>
                </div>
              </section>

              <section className="about-area2 fix pb-padding pt-50 pb-80 content-area2">
                <h2 className="label1">Attendance History</h2>
                <div className="row">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <h4 className="chkin">Check-In Time</h4>
                      <input
                        className="form-control valid textboxstyle"
                        name="checkin_time"
                        id="checkin_time"
                        type="text"
                        placeholder="Check-In Time"
                        value={checkin_time}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <h4>Check-Out Time</h4>
                      <input
                        className="form-control valid textboxstyle float-right"
                        name="checkout_time"
                        id="checkout_time"
                        type="text"
                        value={checkout_time}
                        placeholder="Check-Out Time"
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </div>
    );
  }
}

export default withAuthUser(Attendance);
